import { PostProps } from '../types/blog'
import { PageContextProps, SEOProps } from '../types/pages'

export const getBreadcrumbSchema = (locale: string, post: PostProps) => {
  if (!post.breadcrumb || post.breadcrumb.length === 0) {
    return null
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      ...post.breadcrumb.map((breadcrumbItem, idx) => ({
        '@type': 'ListItem',
        position: idx + 1,
        name: breadcrumbItem.shortTitle ?? breadcrumbItem.title,
        item: `${process.env.GATSBY_SITE_URL}/${locale === 'en' ? '' : `${locale}/`}${breadcrumbItem.slug}`,
      })),
      {
        '@type': 'ListItem',
        position: post.breadcrumb.length + 1,
        name: post.title,
      },
    ],
  }
}

export const getBlogPaginatedSlug = (slug: string, currentPage: number) => {
  if (currentPage > 1) {
    return `${slug}${process.env.GATSBY_BLOG_PAGINATION_PREFIX}${currentPage}/`
  }
  return slug
}

export const getBlogPaginatedSeoContent = (content: SEOProps, currentPage: number) => {
  if (currentPage > 1 && content) {
    return {
      ...content,
      title: `${content.title} - page ${currentPage}`,
      description: `${content.description} - page ${currentPage}`,
    }
  }
  return content
}

export const getBlogPaginatedSlugLocales = (slugLocales: PageContextProps['slugLocales'], currentPage: number) => {
  if (currentPage > 1) {
    return slugLocales.map((sl) => ({
      ...sl,
      value: `${sl.value}${process.env.GATSBY_BLOG_PAGINATION_PREFIX}${currentPage}/`,
    }))
  }
  return slugLocales
}
